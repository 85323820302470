import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React,{useState, useEffect} from "react";
import {Button, Card, Accordion,Nav, Container } from 'react-bootstrap';

import logoImg from "../../images/mg-logo-dark.svg";
import logoBlack from "../../images/mg-logo-black.svg";
import { LOCAL_LOVE_PAGE_URL} from '../common/site/constants'

import lovelogoImg from "../../images/mg-logo-black.svg";
import lovelogoBlack from "../../images/mg-logo-dark.svg";

import careerlogoImg from "../../images/mg-careers-logo-dark.svg";
import careerlogoBlack from "../../images/mg-careers-logo-light.svg";

import myLogoImg from "../../images/my-nav-logo.svg";
import navLogoWhite from "../../images/my-mg-logo-white.svg";
import logoImgLove from "../../images/nav-logo-love-new.svg";
//import "animate.css/animate.min.css";
import  "../header/header.scss";
import $ from "jquery";
import SearchOverlay from "../searchOverlay/SearchOverlay"
import { useAuthState } from '@myaccountServices'

import { GetURL } from "../common/site/functions";

const Header = ( props ) => {

  const [removeClassname, setRemoveClassname] = useState(false)
  const [addclassname,setAddclassname] = useState(false)
  const [hoverclass,setHoverclass] = useState(false)
  const [hoverclass2,setHoverclass2] = useState(false)
  const [hoverclass3,setHoverclass3] = useState(false)
  const [hoverclass4,setHoverclass4] = useState(false)
  const [hoverclass5,setHoverclass5] = useState(false)
  const [hoverclass6,setHoverclass6] = useState(false)
  const [submenuclass, setSubmenuclass] = useState(false)
  const [navLinkClass,setNavLinkClass] = useState(false)
  const [navLinkClass2,setNavLinkClass2] = useState(false)
  const [navLinkClass21,setNavLinkClass21] = useState(false)
  const [navLinkClass3,setNavLinkClass3] = useState(false)
  const [navLinkClass31,setNavLinkClass31] = useState(false)
  const [navLinkClass4,setNavLinkClass4] = useState(false)
  const [navLinkClass5,setNavLinkClass5] = useState(false)
  const [navLinkClass6,setNavLinkClass6] = useState(false)
  const [scroll, setScroll] = useState(false);
  const [isMobileOnly, setIsMobileOnly] = useState(false);
  const { state, services } = useAuthState();

  const [renderComponent, setRenderComponent] = useState(false);

  const [logo, setLogo] = useState('')

  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

  const removeClass = (e) => {
      setSubmenuclass(false)
      setAddclassname(true)
      setRemoveClassname(true)
      setNavLinkClass(false)
      setNavLinkClass2(false)
      setNavLinkClass21(false)
      setNavLinkClass3(false)
      setNavLinkClass31(false)
      setNavLinkClass4(false)
      setNavLinkClass5(false)
      setNavLinkClass6(false)
  }

    const [toggleActive, settoggleActive] = useState(null)
    const toggleClick = (event) => {
    if(toggleActive === event){
    settoggleActive(null)
    }
    else {
    settoggleActive(event)

    }

    }


useEffect(() => {

  window.addEventListener("mousemove", () => {
    if (renderComponent === false) {
        setRenderComponent(true)
    }
  })
  window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
          setRenderComponent(true)
      }
  })
  window.addEventListener("keypress", () => {
    if (renderComponent === false) {
        setRenderComponent(true)
    }
  })

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''

  // check if the device is mobile or not
  if(pageurl){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      setIsMobileOnly(true);;
    } else{
      setIsMobileOnly(false);
    }  
  }

  if(pageurl.indexOf('careers') !== -1) {
    setLogo("careers")
  }
  else if(pageurl.indexOf('/local-loving-life') !== -1) {
    setLogo("love")
  }
  else if((pageurl.indexOf('/loving-life') !== -1) || (pageurl.indexOf('/restaurant-and-bars') !== -1) || (pageurl.indexOf('/shops') !== -1) || (pageurl.indexOf('/events') !== -1) || (pageurl.indexOf('/clubs-and-societies') !== -1) || (pageurl.indexOf('/clubs-and-events') !== -1)) {
    setLogo("love")
  }
  else {
    setLogo("")
  }

  document.querySelector('body').classList.remove('stop-overflow')

  if (document.getElementById("widget") === null) {
      var script = document.createElement('script');
      script.id = 'widget';
      script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      script.async = true;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }, [])

  useEffect(() => {
    let lastScrollTop = 0;

      window.addEventListener("scroll", () => {
        const scrollTop = window.scrollY || window.pageYOffset;
        if(props.page == "results") {
          $("header").hide();
        }else if (scrollTop > lastScrollTop) {
          // Scrolling down
          $("header").hide();
          setScroll(false);
        } else {
          // Scrolling up
          $("header").show();
          setScroll(scrollTop > 40);
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      });
  },[props.page])

  const addClass = () => {
    setAddclassname(!addclassname)
    let domElement=document.querySelector('.stop-overflow')
    if(domElement == null){
      document.querySelector('body').classList.add('stop-overflow')
      $(".submenu-sec").show();
    }
    else{
      document.querySelector('body').classList.remove('stop-overflow')
      //$(".submenu-sec").hide();
      setSubmenuclass(false)
      setNavLinkClass(false)
      setNavLinkClass2(false)
      setNavLinkClass21(false)
      setNavLinkClass3(false)
      setNavLinkClass31(false)
      setNavLinkClass4(false)
      setNavLinkClass5(false)
      setNavLinkClass6(false)
      //toggleClick(1);
      //toggleClick(2);
      //toggleClick(3);
      //toggleClick(4);
      //toggleClick(5);
    }

    //console.log("dommminggggg",domElement);
  }


  const data = useStaticQuery(graphql`
    query{
    allStrapiMenuBurgers(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
        edges {
          node {
            Parent_Label
            Parent_URL {
              id
            }
            Sub_Menus {
              Parent_Label
              Parent_URL {
                id
              }
              Add_Sub_Menus {
                Label
                URL {
                  id
                }
              }
            }
            Columns
          }
        }
    }
    }
  `);

  function searchOverlay(e) {
    $("body")
        .addClass("modal-open")
        .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }

  function scrolltop() {

        // load on top
        $(document).ready(function(){
          $("html, body").scrollTop(0);
        });
        // load on top
  }

  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''


  return(
  <header className={
    addclassname ? "header active-header" : hoverclass || hoverclass2 || hoverclass3 || hoverclass4 || hoverclass5 || hoverclass6 ? "header mouse-active" : scroll ? "header header-two-main" : removeClassname ? "header" : "header"
  }>
  {renderComponent &&
    <SearchOverlay/>
  }

  <Container fluid className="d-flex justify-content-between align-items-center">
  {logo != 'careers' && logo != 'love' ?
    <Link className="nav-brand" to="/">
      <img loading="lazy" className="logo-white" src={logoImg} alt="Martyn Gerrard logo"/>
      <img loading="lazy" className="logo-black" src={logoBlack} alt="Martyn Gerrard logo"/>
    </Link> : logo != 'careers' && logo == 'love' ?
    <Link className="nav-brand" to="/">
      <img loading="lazy" className="logo-white" src={lovelogoBlack} alt="Martyn Gerrard logo"/>
      <img loading="lazy" className="logo-black" src={lovelogoImg} alt="Martyn Gerrard logo"/>
    </Link> :
    <Link className="nav-brand" to="/">
      <img loading="lazy" className="logo-white" src={careerlogoBlack} alt="Martyn Gerrard logo"/>
      <img loading="lazy" className="logo-black" src={careerlogoImg} alt="Martyn Gerrard logo"/>
    </Link>
    }
    <div className="responsive-menu d-flex align-items-center d-lg-none">
      <Link className="search-icn" to="#" onClick={searchOverlay}>
        <i className="icon-search-icon"></i>
      </Link>
      <strong className="toggle-strip d-flex align-items-center"
        onClick={e=>addClass()}>
          <span>&nbsp;</span>
      </strong>
    </div>
    <Nav className="menu-wrap  d-lg-flex"
        activeKey="/home"
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >

        {data.allStrapiMenuBurgers.edges.map(({ node }, i) => {
          let url = GetURL(node.Parent_URL.id)
          return <>
            {node.Sub_Menus.length === 0 &&
              <Nav.Item>
                <Link className={navLinkClass6 ? "nav-click nav-link" : "nav-link"}  to={`/${url}`}>{node.Parent_Label}</Link>
              </Nav.Item>
            }

            {node.Sub_Menus.length !== 0 && node.Columns === 'Multiple' &&
              <Nav.Item>

                <Link to={`/${url}`} className="head-link desktop-submenu-nav"><div className={ navLinkClass ? "nav-click nav-link"  : " nav-link"} onClick={()=>setNavLinkClass(true)}>{node.Parent_Label} <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div></Link>

                <div className={ navLinkClass ? "nav-click nav-link mobile-submenu-nav"  : " nav-link mobile-submenu-nav"} onClick={()=>setNavLinkClass(true)}><Link to={`/${url}`} className="head-link">{node.Parent_Label}</Link> <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div>


                    <div className="submenu-sec" >
                    <div className="submenu-wrap">
                      <Container>
                          <Link onClick={e=>removeClass(i,true)} id='back-icon' className="back-btn text-uppercase d-lg-none" to="#">
                              <span  className="back-arrow">
                              <i className="icon-arrow-right"></i>
                              </span> back to main menu
                          </Link>
                          <Accordion>
                          {node.Sub_Menus.map((Sub_Menus, i) => {
                            console.log("Sub_Menus=>",Sub_Menus)
                            let sub_menu_url = ''
                            if(Sub_Menus.Parent_URL) {
                            sub_menu_url = GetURL(Sub_Menus.Parent_URL.id)
                            }
                            console.log("isMobile =>",isMobileOnly)
                            return <>
                                <Card className={i === 6 ? "block-management" : ''}>
                          <Card.Header className={toggleActive === i+1 ? "minus" : ''}>
                          <Accordion.Toggle as={Button} onClick={e=>toggleClick(i+1)} variant="link" eventKey={i+1}>
                              {sub_menu_url ?
                                  <Link to={`/${sub_menu_url}`} className="sub-head">{Sub_Menus.Parent_Label}</Link>
                                :
                                  <span className="sub-head-no-line">{Sub_Menus.Parent_Label}</span>
                              }
                          </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={i+1}>
                          <Card.Body>
                          <ul className="menu-nav">
                            {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                            let sub_url = GetURL(Add_Sub_Menus.URL.id)
                              return <li key={i}>
                                <Link to={`/${sub_url}`}><span>{Add_Sub_Menus.Label}</span></Link>
                              </li>
                            })}
                          </ul>


                              {i === 3 ? <>
                              {node.Sub_Menus.slice(6).map((Sub_Menus, i) => {
                              let url = GetURL(Sub_Menus.Parent_URL.id)
                              return (
                                <>
                                            {Sub_Menus.Parent_Label === "Block Management" &&
                              <div className="block-management-desktop">
                              <div className="btn">
                              <Link to={`/${url}`} className="sub-head">{Sub_Menus.Parent_Label}</Link>
                              </div>
                              <ul className="menu-nav">
                                {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                                  let sub_menu_url = GetURL(Add_Sub_Menus.URL.id)
                                  return <li><Link to={`/${sub_menu_url}`}><span>{Add_Sub_Menus.Label}</span></Link></li>
                                })}
                              </ul>
                              </div>
                                 }
                                          </>
                                )
                              })}
                              </> : ''}

                          </Card.Body>
                          </Accordion.Collapse>
                      </Card>
                              </>
                            })}
                          </Accordion>
                      </Container>
                    </div>
                  </div>
              </Nav.Item>
            }

            {node.Sub_Menus.length !== 0 && node.Columns === 'Single' &&
              <Nav.Item>

                {node.Parent_Label === 'News & Guides' ?
                <>
                <Link to={`/${url}`} className="head-link desktop-submenu-nav"><div className={ navLinkClass3 ? "nav-click nav-link"  : " nav-link"} onClick={()=>setNavLinkClass31(true)}>{node.Parent_Label} <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div></Link>

                <div className={ navLinkClass3 ? "nav-click nav-link mobile-submenu-nav"  : " nav-link mobile-submenu-nav"} onClick={()=>setNavLinkClass3(true)}><Link to={`/${url}`} className="head-link">{node.Parent_Label}</Link> <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div>
                </>
                :
                <>
                <Link to={`/${url}`} className="head-link desktop-submenu-nav"><div className={ navLinkClass2 ? "nav-click nav-link"  : " nav-link"} onClick={()=>setNavLinkClass21(true)}>{node.Parent_Label} <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div></Link>

                <div className={ navLinkClass2 ? "nav-click nav-link mobile-submenu-nav"  : " nav-link mobile-submenu-nav"} onClick={()=>setNavLinkClass2(true)}><Link to={`/${url}`} className="head-link">{node.Parent_Label}</Link> <span className="arrow d-lg-none"><i className="icon-arrow-right"></i> </span></div>
                </>

                }

                    <div className="submenu-sec single-col-submenu" >
                    <div className="submenu-wrap">
                      <Container>
                          <Link onClick={e=>removeClass(i,true)} id='back-icon' className="back-btn text-uppercase d-lg-none" to="#">
                              <span  className="back-arrow">
                              <i className="icon-arrow-right"></i>
                              </span> back to main menu
                          </Link>
                          <Accordion>
                          <ul>
                          {node.Sub_Menus.map((Sub_Menus, i) => {
                            let sub_menu_url = GetURL(Sub_Menus.Parent_URL.id)
                            return <li>
                                  <Link to={`/${sub_menu_url}`} className="sub-head">{Sub_Menus.Parent_Label}</Link>
                              </li>
                            })}
                          </ul>
                          </Accordion>
                      </Container>
                    </div>
                  </div>
              </Nav.Item>
            }

          </>
        })}

        <Nav.Item className="d-none d-lg-block search-icon-wrap">
          <Nav.Link eventKey="link-2" onClick={searchOverlay}><i className="icon-search-icon"></i></Nav.Link>
        </Nav.Item>        
      </Nav>
  </Container>

    {/* <SubMenu
      removeClass={removeClass}
      issubmenuclass={submenuclass}
     /> */}

  </header>
)
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
