const rentPrices = [
  
  {
    value: "10000",
    label: "£10,000 pcm +"
  },
  {
    value: "5000",
    label: "£5,000 pcm"
  },
  {
    value: "4500",
    label: "£4,500 pcm"
  },
  {
    value: "4000",
    label: "£4,000 pcm"
  },
  {
    value: "3500",
    label: "£3,500 pcm"
  },
  {
    value: "3000",
    label: "£3,000 pcm"
  },
  {
    value: "2500",
    label: "£2,500 pcm"
  },
  {
    value: "2000",
    label: "£2,000 pcm"
  },
  {
    value: "1500",
    label: "£1,500 pcm"
  },
  {
    value: "1000",
    label: "£1,000 pcm"
  },
  {
    value: "500",
    label: "£500 pcm"
  }
]

const salesPrices = [
  
  {
    value: "4500000",
    label: "£4,500,000 +"
  },
  {
    value: "4250000",
    label: "£4,250,000"
  },
  {
    value: "4000000",
    label: "£4,000,000"
  },
  {
    value: "3750000",
    label: "£3,750,000"
  },
  {
    value: "3500000",
    label: "£3,500,000"
  },
  {
    value: "3250000",
    label: "£3,250,000"
  },
  {
    value: "3000000",
    label: "£3,000,000"
  },
  {
    value: "2750000",
    label: "£2,750,000"
  },
  {
    value: "2500000",
    label: "£2,500,000"
  },
  {
    value: "2250000",
    label: "£2,250,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  },
  {
    value: "1900000",
    label: "£1,900,000"
  },
  {
    value: "1800000",
    label: "£1,800,000"
  },
  {
    value: "1700000",
    label: "£1,700,000"
  },
  {
    value: "1600000",
    label: "£1,600,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "1400000",
    label: "£1,400,000"
  },
  {
    value: "1300000",
    label: "£1,300,000"
  },
  {
    value: "1200000",
    label: "£1,200,000"
  },
  {
    value: "1100000",
    label: "£1,100,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "900000",
    label: "£900,000"
  },
  {
    value: "875000",
    label: "£875,000"
  },
  {
    value: "850000",
    label: "£850,000"
  },
  {
    value: "825000",
    label: "£825,000"
  },
  {
    value: "800000",
    label: "£800,000"
  },
  {
    value: "775000",
    label: "£775,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "725000",
    label: "£725,000"
  },
  {
    value: "700000",
    label: "£700,000"
  },
  {
    value: "675000",
    label: "£675,000"
  },
  {
    value: "650000",
    label: "£650,000"
  },
  {
    value: "625000",
    label: "£625,000"
  },
  {
    value: "600000",
    label: "£600,000"
  },
  {
    value: "575000",
    label: "£575,000"
  },
  {
    value: "550000",
    label: "£550,000"
  },
  {
    value: "525000",
    label: "£525,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "475000",
    label: "£475,000"
  },
  {
    value: "450000",
    label: "£450,000"
  },
  {
    value: "425000",
    label: "£425,000"
  },
  {
    value: "400000",
    label: "£400,000"
  },
  {
    value: "375000",
    label: "£375,000"
  },
  {
    value: "350000",
    label: "£350,000"
  },
  {
    value: "325000",
    label: "£325,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "275000",
    label: "£275,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "225000",
    label: "£225,000"
  },
  {
    value: "200000",
    label: "£200,000"
  }
]

const landPrices = [
  
  {
    value: "4500000",
    label: "£4,500,000 +"
  },
  {
    value: "4250000",
    label: "£4,250,000"
  },
  {
    value: "4000000",
    label: "£4,000,000"
  },
  {
    value: "3750000",
    label: "£3,750,000"
  },
  {
    value: "3500000",
    label: "£3,500,000"
  },
  {
    value: "3250000",
    label: "£3,250,000"
  },
  {
    value: "3000000",
    label: "£3,000,000"
  },
  {
    value: "2750000",
    label: "£2,750,000"
  },
  {
    value: "2500000",
    label: "£2,500,000"
  },
  {
    value: "2250000",
    label: "£2,250,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  },
  {
    value: "1900000",
    label: "£1,900,000"
  },
  {
    value: "1800000",
    label: "£1,800,000"
  },
  {
    value: "1700000",
    label: "£1,700,000"
  },
  {
    value: "1600000",
    label: "£1,600,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "1400000",
    label: "£1,400,000"
  },
  {
    value: "1300000",
    label: "£1,300,000"
  },
  {
    value: "1200000",
    label: "£1,200,000"
  },
  {
    value: "1100000",
    label: "£1,100,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "900000",
    label: "£900,000"
  },
  {
    value: "875000",
    label: "£875,000"
  },
  {
    value: "850000",
    label: "£850,000"
  },
  {
    value: "825000",
    label: "£825,000"
  },
  {
    value: "800000",
    label: "£800,000"
  },
  {
    value: "775000",
    label: "£775,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "725000",
    label: "£725,000"
  },
  {
    value: "700000",
    label: "£700,000"
  },
  {
    value: "675000",
    label: "£675,000"
  },
  {
    value: "650000",
    label: "£650,000"
  },
  {
    value: "625000",
    label: "£625,000"
  },
  {
    value: "600000",
    label: "£600,000"
  },
  {
    value: "575000",
    label: "£575,000"
  },
  {
    value: "550000",
    label: "£550,000"
  },
  {
    value: "525000",
    label: "£525,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "475000",
    label: "£475,000"
  },
  {
    value: "450000",
    label: "£450,000"
  },
  {
    value: "425000",
    label: "£425,000"
  },
  {
    value: "400000",
    label: "£400,000"
  },
  {
    value: "375000",
    label: "£375,000"
  },
  {
    value: "350000",
    label: "£350,000"
  },
  {
    value: "325000",
    label: "£325,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "275000",
    label: "£275,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "225000",
    label: "£225,000"
  },
  {
    value: "200000",
    label: "£200,000"
  }
]

const newhomesPrices = [
  
  {
    value: "4500000",
    label: "£4,500,000 +"
  },
  {
    value: "4250000",
    label: "£4,250,000"
  },
  {
    value: "4000000",
    label: "£4,000,000"
  },
  {
    value: "3750000",
    label: "£3,750,000"
  },
  {
    value: "3500000",
    label: "£3,500,000"
  },
  {
    value: "3250000",
    label: "£3,250,000"
  },
  {
    value: "3000000",
    label: "£3,000,000"
  },
  {
    value: "2750000",
    label: "£2,750,000"
  },
  {
    value: "2500000",
    label: "£2,500,000"
  },
  {
    value: "2250000",
    label: "£2,250,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  },
  {
    value: "1900000",
    label: "£1,900,000"
  },
  {
    value: "1800000",
    label: "£1,800,000"
  },
  {
    value: "1700000",
    label: "£1,700,000"
  },
  {
    value: "1600000",
    label: "£1,600,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "1400000",
    label: "£1,400,000"
  },
  {
    value: "1300000",
    label: "£1,300,000"
  },
  {
    value: "1200000",
    label: "£1,200,000"
  },
  {
    value: "1100000",
    label: "£1,100,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "900000",
    label: "£900,000"
  },
  {
    value: "875000",
    label: "£875,000"
  },
  {
    value: "850000",
    label: "£850,000"
  },
  {
    value: "825000",
    label: "£825,000"
  },
  {
    value: "800000",
    label: "£800,000"
  },
  {
    value: "775000",
    label: "£775,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "725000",
    label: "£725,000"
  },
  {
    value: "700000",
    label: "£700,000"
  },
  {
    value: "675000",
    label: "£675,000"
  },
  {
    value: "650000",
    label: "£650,000"
  },
  {
    value: "625000",
    label: "£625,000"
  },
  {
    value: "600000",
    label: "£600,000"
  },
  {
    value: "575000",
    label: "£575,000"
  },
  {
    value: "550000",
    label: "£550,000"
  },
  {
    value: "525000",
    label: "£525,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "475000",
    label: "£475,000"
  },
  {
    value: "450000",
    label: "£450,000"
  },
  {
    value: "425000",
    label: "£425,000"
  },
  {
    value: "400000",
    label: "£400,000"
  },
  {
    value: "375000",
    label: "£375,000"
  },
  {
    value: "350000",
    label: "£350,000"
  },
  {
    value: "325000",
    label: "£325,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "275000",
    label: "£275,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "225000",
    label: "£225,000"
  },
  {
    value: "200000",
    label: "£200,000"
  }
]

const commercialPrices = [
  
  {
    value: "5000000",
    label: "£5,000,000 +"
  },
  {
    value: "3000000",
    label: "£3,000,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "900000",
    label: "£900,000"
  },
  {
    value: "800000",
    label: "£800,000"
  },
  {
    value: "700000",
    label: "£700,000"
  },
  {
    value: "600000",
    label: "£600,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "400000",
    label: "£400,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "200000",
    label: "£200,000"
  },
  {
    value: "100000",
    label: "£100,000"
  },
]





export { rentPrices, salesPrices , landPrices , newhomesPrices , commercialPrices}